const homeSwiper = new Swiper('#home-swiper', {
  slidesPerView: 1,
  effect: 'fade',

  autoplay: {
    delay: 5000,
  },

  pagination: {
    el: '#home-swiper-pagination',
    clickable: true,
  },
});

const planesSwiper = new Swiper('#planes-swiper', {
  slidesPerView: 1,
  effect: 'fade',

  autoplay: {
    delay: 5000,
  },

  pagination: {
    el: '#planes-pagination',
    clickable: true,
  },
});

const partnersSwiper = new Swiper('#partners-swiper', {
  slidesPerView: 5,
  loop: true,

  autoplay: {
    delay: 5000,
  },

  navigation: {
    nextEl: '.home-partners__next',
    prevEl: '.home-partners__prev',
  },

  breakpoints: {
    1: {
      slidesPerView: 1,
    },
    480: {
      slidesPerView: 2,
    },
    768: {
      slidesPerView: 3,
    },
    1024: {
      slidesPerView: 4,
    },
    1200: {
      slidesPerView: 5,
    },
  },
});

const mediasSwiper = new Swiper('#medias-swiper', {
  slidesPerView: 6,
  loop: true,

  autoplay: {
    delay: 5000,
  },

  breakpoints: {
    1: {
      slidesPerView: 1,
    },
    480: {
      slidesPerView: 2,
    },
    768: {
      slidesPerView: 3,
    },
    1024: {
      slidesPerView: 4,
    },
    1200: {
      slidesPerView: 5,
    },
  },
});

const player = new Plyr('#player');
const plyr = document.querySelector('.plyr');
const bg = document.querySelector('.home-banner__bg');

plyr.classList.add('swiper-slide');
bg.style.backgroundImage = `url('${bg.getAttribute('data-bg')}')`;

player.on('playing', () => {
  homeSwiper.autoplay.stop();
  homeSwiper.allowSlideNext = false;
  homeSwiper.allowSlidePrev = false;
});

player.on('ended', () => {
  homeSwiper.autoplay.start();
  homeSwiper.allowSlideNext = true;
  homeSwiper.allowSlidePrev = true;
});

player.on('pause', () => {
  homeSwiper.autoplay.start();
  homeSwiper.allowSlideNext = true;
  homeSwiper.allowSlidePrev = true;
});
